'use client';

import {
  FC,
  PropsWithChildren,
  ReactNode,
  createContext,
  useCallback,
  useContext,
  useState,
} from 'react';
import { ToastProvider } from '@bts-web/design-system/component/toast';
import { css } from '@bts-web/utils-style-engine';
import { Toast } from '@bts-web/design-system/component/toast';
import { getNotificationIcon } from './utils/getNotificationIcon';

const NOTIFICATION_DURATION = 5000;

export type NotificationPosition = 'topRight' | 'bottomRight';

export type TNotificationEntry = {
  open?: boolean;
  title?: string;
  subtitle?: string | React.ReactElement;
  action?: { component: ReactNode; altText: string };
  withClose?: boolean;
  visual?: 'info' | 'error' | 'warning' | 'success' | 'neutral';
  id: string;
  customDuration?: number | 'infinite';
  position?: NotificationPosition;
};

export type TNotificationTriggerType = Omit<
  TNotificationEntry,
  'open' | 'id'
> & {
  id?: string;
};

export type INotificationContext = {
  setAppNotification: (notification: TNotificationTriggerType) => void;
  closeAllNotifications: () => void;
};

const NotificationContext = createContext<INotificationContext>({
  setAppNotification: (_: TNotificationTriggerType) => {
    return;
  },
  closeAllNotifications: () => {
    return;
  },
});

export const NotificationContextProvider: FC<PropsWithChildren> = ({
  children,
}) => {
  const [notifications, setNotifications] = useState<TNotificationEntry[]>([]);

  const [position, setPosition] = useState<NotificationPosition>('topRight');

  const removeNotification = useCallback((id: string) => {
    setNotifications((current) => current.filter((notif) => notif.id !== id));
  }, []);

  const triggerNotification = useCallback(
    (passedNotif: TNotificationTriggerType) => {
      if (passedNotif.position !== position) {
        setPosition(passedNotif.position ?? 'topRight');
      }

      const newNotification: TNotificationEntry = {
        open: true,
        visual: passedNotif.visual ?? 'info',
        title: passedNotif.title ?? 'Undefined',
        subtitle: passedNotif.subtitle ?? '',
        withClose: passedNotif.withClose ?? false,
        id: passedNotif.id ?? `notification-${Math.random()}`,
        customDuration: passedNotif.customDuration ?? NOTIFICATION_DURATION,
      };

      setNotifications((current) => [...current, newNotification]);

      // Set up auto-removal for non-infinite notifications
      if (newNotification.customDuration !== 'infinite') {
        setTimeout(() => {
          removeNotification(newNotification.id);
        }, newNotification.customDuration);
      }
    },
    [position, removeNotification],
  );

  return (
    <NotificationContext.Provider
      value={{
        setAppNotification: triggerNotification,
        closeAllNotifications: () => {
          setNotifications([]);
        },
      }}
    >
      <ToastProvider
        viewPortClassName={css({
          position: 'fixed',
          right: 0,
          display: 'flex',
          flexDirection: 'column',
          gap: '10px',
          width: '390px',
          maxWidth: '100vw',
          margin: 0,
          listStyle: 'none',
          zIndex: 2147483647,
          outline: 'none',
          padding: 'large',
          ...(position === 'topRight'
            ? {
                top: 72,
              }
            : {}),
          ...(position === 'bottomRight'
            ? {
                bottom: 72,
              }
            : {}),
          '& [data-element="toast-title"]': {
            display: 'flex',
            gap: 'small',
            flex: 1,
            fontSize: 'label.medium_medium',
            fontWeight: 'label.medium_medium',
            letterSpacing: 'label.medium_medium',
            lineHeight: 'label.medium_medium',
          },
          '& [data-element="toast-title-container"]': {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'flex-start',
            flex: 'auto',
            gap: 'extra_small',
            width: '100%',
          },
          '& [data-element="toast-content"]': {
            paddingLeft: 'extra_large',
            fontSize: 'body.medium',
            fontWeight: 'body.medium',
            letterSpacing: 'body.medium',
            lineHeight: 'body.medium',
          },
          '& [data-element="toast-action-container"]': {
            paddingLeft: 'extra_large',
          },
          '& [data-element="icon-visual"]': {
            minWidth: 24,
          },
        })}
      >
        {notifications.map((notification) => {
          return (
            <Toast
              key={notification.id}
              open={notification.open}
              onOpenChange={(open) => {
                if (!open) {
                  removeNotification(notification.id);
                }
              }}
              // duration={Infinity} // We're managing duration ourselves, so we don't need this
              subtitle={notification.subtitle}
              action={notification.action}
              withClose={notification.withClose}
              title={
                <>
                  {getNotificationIcon(notification?.visual || 'info')}
                  <div>{notification.title}</div>
                </>
              }
              className={css({
                borderRadius: '8px',
                borderWidth: '1px',
                boxShadow: `4px 8px 8px 0px rgba(0, 0, 0, 0.25)`,
                display: 'flex',
                flexDirection: 'column',
                maxWidth: 342,
                width: '100%',
                padding: 'small',
                gap: 'extra_small_2',
                '&[data-state="open"]': {
                  animation: 'slideIn 150ms cubic-bezier(0.16, 1, 0.3, 1)',
                },
                '&[data-state="closed"]': {
                  animation: 'slideOut 100ms cubic-bezier(0.16, 1, 0.3, 1)',
                },
                ...(notification?.visual === 'info'
                  ? {
                      background: 'information.fill_focused',
                      borderColor: 'information.stroke_primary',
                      '& [data-element="toast-title"]': {
                        color: 'information.text_primary',
                        '& > iconify-icon': {
                          color: 'information.stroke_primary',
                        },
                      },
                    }
                  : {}),
                ...(notification?.visual === 'error'
                  ? {
                      background: 'negative.fill_focused',
                      borderColor: 'negative.stroke_primary',
                      '& [data-element="toast-title"]': {
                        color: 'negative.text_primary',
                        '& > iconify-icon': {
                          color: 'negative.stroke_primary',
                        },
                      },
                    }
                  : {}),
                ...(notification?.visual === 'warning'
                  ? {
                      background: 'warning.fill_focused',
                      borderColor: 'warning.stroke_primary',
                      '& [data-element="toast-title"]': {
                        color: 'warning.text_primary',
                        '& > iconify-icon': {
                          color: 'warning.stroke_primary',
                        },
                      },
                    }
                  : {}),
                ...(notification?.visual === 'success'
                  ? {
                      background: 'positive.fill_focused',
                      borderColor: 'positive.stroke_primary',
                      '& [data-element="toast-title"]': {
                        color: 'positive.text_primary',
                        '& > iconify-icon': {
                          color: 'positive.stroke_primary',
                        },
                      },
                    }
                  : {}),
                ...(notification?.visual === 'neutral'
                  ? {
                      background: 'neutrals.fill_focused',
                      borderColor: 'neutrals.stroke_primary',
                      '& [data-element="toast-title"]': {
                        color: 'neutrals.text_primary',
                        '& > iconify-icon': {
                          color: 'neutrals.stroke_primary',
                        },
                      },
                    }
                  : {}),
              })}
            />
          );
        })}
      </ToastProvider>
      {children}
    </NotificationContext.Provider>
  );
};

export const useAppNotification = () => {
  const context = useContext(NotificationContext);

  if (!context) {
    throw new Error(
      'useAppNotification must be used within a NotificationContext',
    );
  }

  return context;
};
