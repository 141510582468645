import { datadogRum } from '@datadog/browser-rum';

export const initDataDogRum = () => {
  // see .gitlab-ci/apps/lbbw.yml for Datadog variables
  const dataDogEnv = process.env.NEXT_PUBLIC_DATADOG_ENV;

  const dataDogRumService = process.env.NEXT_PUBLIC_DATADOG_RUM_SERVICE;

  const dataDogSite = process.env.NEXT_PUBLIC_DATADOG_SITE;

  const appVersion = process.env.NEXT_PUBLIC_APP_VERSION;

  const apiUrl = process.env.NEXT_PUBLIC_GQL_API_ENDPOINT_URL;

  if (dataDogEnv && apiUrl && appVersion && !isDatadogRumInitialized()) {
    datadogRum.init({
      applicationId: '69a099da-a5ab-45b5-a633-8faa3be2d1a2',
      clientToken: 'pub5a20334675db4bf88a2ee2f3811487b4',
      site: dataDogSite,
      service: dataDogRumService,
      env: dataDogEnv,
      version: appVersion,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 0,
      traceSampleRate: 50,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: 'mask-user-input',
      storeContextsAcrossPages: true,
      allowedTracingUrls: [
        { match: apiUrl, propagatorTypes: ['tracecontext'] },
      ],
    });
  }
};

export const setDataDogUser = (userId: string) => {
  const currentUser = datadogRum.getUser();

  if (currentUser && currentUser.id === userId) {
    return;
  }

  datadogRum.setUser({ id: userId });
};

export const dataDogErrorHelperRoot = (
  ...params: Parameters<typeof datadogRum.addError>
) => {
  datadogRum.addError(...params);
};

export const isDatadogRumInitialized = (): boolean => {
  try {
    const internalContext = datadogRum.getInternalContext();

    return !!internalContext?.session_id;
  } catch (error) {
    console.error('Error checking Datadog RUM initialization:', error);

    return false;
  }
};
