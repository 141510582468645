import { ReactNode } from 'react';
import { Description, Title, Action, Root, Close } from '@radix-ui/react-toast';
import { Icon } from '@bts-web/design-system/component/icon';

export interface ToastProps {
  internal: {
    className?: string;
  };
  external: {
    title?: ReactNode;
    open?: boolean;
    onOpenChange: (open: boolean) => void;
    duration?: number;
    subtitle?: string | React.ReactElement;
    action?: { component: ReactNode; altText: string };
    withClose?: boolean;
  };
}

export const Toast = ({
  className,
  onOpenChange,
  open,
  subtitle,
  title,
  action,
  withClose = false,
  duration = Infinity,
}: ToastProps['internal'] & ToastProps['external']) => {
  return (
    <Root
      className={className}
      open={open}
      onOpenChange={onOpenChange}
      data-testid="toast-root"
      duration={duration}
    >
      <div
        data-element="toast-title-container"
        data-testid="toast-title-container"
      >
        <Title data-element="toast-title" data-testid="toast-title">
          {title}
        </Title>

        {withClose && (
          <Close aria-label="Close">
            <span
              aria-hidden
              data-element="toast-close-icon"
              data-testid="toast-close-icon"
            >
              <Icon icon="close" theme="regular" size="12" />
            </span>
          </Close>
        )}
      </div>

      {subtitle && (
        <Description data-element="toast-content" data-testid="toast-content">
          {subtitle}
        </Description>
      )}

      {action && (
        <div
          data-element="toast-action-container"
          data-testid="toast-action-container"
        >
          <Action
            data-element="toast-action"
            data-testid="toast-action"
            asChild
            altText={action.altText}
          >
            {action.component}
          </Action>
        </div>
      )}
    </Root>
  );
};
