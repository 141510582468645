'use client';

import { FC, ReactNode } from 'react';
import { Icon } from '@bts-web/design-system/component/icon';
import {
  Link,
  linkStyles,
  LinkSizeVariants,
  LinkVisualVariants,
} from '../Link/Link';

type BaseDocumentationExternalLinkProps = {
  href: string;
  download?: string;
  size?: LinkSizeVariants;
  visual?: LinkVisualVariants;
  // this is to be used when the target site has 'Refused to display 'URL' in a frame because it set 'X-Frame-Options' to 'sameorigin'.' so we open the link in a new tab
  useRawOpenUrl?: boolean;
  icon?: string;
};

type DocumentationExternalLinkProps =
  | (BaseDocumentationExternalLinkProps & {
      label: string;
      children?: never;
    })
  | (BaseDocumentationExternalLinkProps & {
      label?: never;
      children: ReactNode;
    });

const DocumentationExternalLink: FC<DocumentationExternalLinkProps> = ({
  label,
  href,
  download,
  children,
  useRawOpenUrl,
  size = 'small',
  visual = 'primary',
  icon,
}) => {
  const route = `/document?documentUrl=${href}&documentTitle=${label ? label : typeof children === 'string' ? children : ''}`;

  const downloadLinkProps = { href, download };

  const navigationLinkProps = { href: route, passHref: true };

  const externalLinkProps = { href, target: '_blank' };

  const props = download
    ? downloadLinkProps
    : useRawOpenUrl
      ? externalLinkProps
      : navigationLinkProps;

  const isNative = useRawOpenUrl || download;

  const Element = isNative ? 'a' : Link;

  return (
    <Element
      {...props}
      aria-label={`external-link`}
      className={linkStyles({ visual, size })}
      data-testid="documentation-external-link"
      role="link"
      tabIndex={0}
      {...(icon &&
        !isNative && { RightComponent: <Icon theme="regular" icon={icon} /> })}
    >
      {label || children}
    </Element>
  );
};

export { DocumentationExternalLink };
