// Necessary if using App Router to ensure this file runs on the client
'use client';

import { useEffect } from 'react';
import { initDataDogRum } from './dataDogUtilsEntryPoint';

// does not run RUM on local

const DatadogRumInit = () => {
  useEffect(() => {
    initDataDogRum();
  }, []);

  return null;
};

export { DatadogRumInit };
